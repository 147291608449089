<template>
    <vue-basic-alert :duration="400"  ref="alert" />
    <div class="home">
        <div class="home-main">
      
            <div class="content">
                <!-- <span><b>welcome! | ආයුබෝවන්!</b> </span>
               <br>
                <span><b>Local Government Online Payment Management System</b></span>
                <h4><b>පළාත් පාලන මාර්ගගත ගෙවීම් කළමනාකරණ පද්ධතිය වෙත ඔබව සාදරයෙන් පිළිගනිමු...</b></h4> -->
               <!-- <p><b>We are constantly trying to provide services for your convenience. In order to provide you
                 with the best service standards, elg hereby brings your own Local Authority much closer to you now!
                 </b>
               </p>
                <p> <b>24x7 Online Payment Facility</b></p> -->
         
            </div>
          
        </div>
        <!-- <p>Receipt:  {{ receipt }}</p>
        <p>Receipt:  {{ userNic }}</p> -->
        
    <br><br>
    <div v-if="userNic!==null && receipt!==null">
       <div class="thank-container">

<h1>
    <p>
        <span>T</span>
        <span>h</span>
        <span>a</span>
        <span>n</span>
        <span>k</span>
    &nbsp;
        <span>Y</span>
        <span>o</span>
        <span>u!</span>
        &nbsp;
        <span>P</span>
        <span>a</span>
        <span>y</span>
        <span>m</span>
        <span>e</span>
        <span>n</span>
        <span>t</span>
    
    &nbsp;
        <span>i</span>
        <span>s</span>
        &nbsp;
        <span>S</span>
        <span>u</span>
        <span>c</span>
        <span>c</span>
        <span>e</span>
        <span>s</span>
        <span>s</span>
        <span>f</span>
        <span>u</span>
        <span>l</span>
        <span>l</span>
    </p>
    <p>
       

</p>
<br><br>
<p>
     <img src="../assets/images/best-seller.png" alt="" class="home-img">  &nbsp;&nbsp;&nbsp;

    <input type="button" @click="generatePDF('receipt')" value="Download Receipt PDF" class="btn" />
</p>
 
</h1>
</div>
<div class="register-container" id="receipt">
     <div class="register-form-container">
 
            
 <form id="Invoince"  novalidate autocomplete="off">
     <pre>DateTime :{{ new Date().toLocaleString()}}</pre>
     <div class="heading">
     <span>Local Government Online Payment Management System</span>
               
         <h3><b>Payment Reciept</b></h3>
         <h5 v-if="theSabhaData && theSabhaData[0]">{{ theSabhaData[0].sb_address }}</h5> 
                    <h5 v-if="theSabhaData && theSabhaData[0]">Tel:{{ theSabhaData[0].sb_contact}}, Fax:{{ theSabhaData[0].fax}}</h5>
         
         
         
       
 </div>
     <div class="table-responsive">
         
       <h6>Invoice No : &nbsp;{{ receipt }} </h6> 
     <table v-if="paytype=='shoprent'" class="table ">

         <tbody>
             <tr>
                 <td class="bg-light-gray"><label for="Uname"><b>Your NIC | හැඳුනුම්පත් අංකය </b></label></td>
                 <td > <label for="Uname" v-if="matchUser">{{ matchUser.cus_nic }}</label></td>
             </tr>
             <tr>
                 <td class="bg-light-gray"><label for="Uname"><b>Your Name | නම </b></label></td>
                 <td ><label for="Uname" v-if="matchUser">{{ matchUser.cus_name }}</label></td>
             </tr>
            
             <tr>
                 <td class="bg-light-gray"><label for="year"><b>Year | වර්ෂය</b></label></td>
                 <td ><label for="Uname" v-if="rentData && rentData[0]"> {{ rentData[0].rent_year }}</label></td>  
             </tr>
             <tr>
                 <td class="bg-light-gray" > <label for="month"><b>Month | මාසය </b></label></td>
                 <td ><label for="Uname" v-if="rentData && rentData[0]">{{getMonthName(rentData[0].rent_month) }}</label> </td> 
             </tr>
             <tr>
                 <td class="bg-light-gray"><label for="rdes"><b>Remarks  | ගෙවීම් විස්තරය</b> </label></td>
                 <td ><label for="Uname" v-if="rentData && rentData[0]">{{ rentData[0].rent_description }}</label></td>
             </tr>
         </tbody>
     </table>
     <table v-if="paytype=='tax'" class="table ">

<tbody>
    <tr>
                            <td class="bg-light-gray"><label for="year"><b>Ward Number | කොට්ඨාශ අංකය</b></label></td>
                            <td ><label for="Uname" v-if="taxData && taxData[0]">{{ taxData[0].tax_ward }}</label></td>  
                             
                        </tr> <tr>
                            <td class="bg-light-gray"><label for="year"><b>Name of Road,or Street | පාර හෝ වීදියේ නම</b></label></td>
                            <td ><label for="Uname" v-if="taxData && taxData[0]">{{ taxData[0].tax_street }}</label></td>  
                             
                        </tr>
    <tr>
                            <td class="bg-light-gray"><label for="year"><b>Assessment Number | වරිපනම් අංකය</b></label></td>
                            <td ><label for="Uname" v-if="taxData && taxData[0]">{{ taxData[0].tax_no }}</label></td>  
                             
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Pay Amount | ගෙවන මුදල(රු.) </b></label></td>
                            <td ><label for="Uname" v-if="taxData && taxData[0]">{{ taxData[0].tax_price }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු  </b></label></td>
                            <td ><label for="Uname">{{ bankfee }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Gand Total | ගෙවිය යුතු මුළු මුදල </b></label></td>
                            <td ><label for="Uname">{{ grandPayTotal}}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="rdes"><b>Remarks  | ගෙවීම් විස්තරය</b> </label></td>
                            <td ><label for="Uname" v-if="taxData && taxData[0]">{{ taxData[0].tax_description }}</label></td>
                        </tr>   

</tbody>
</table>
<table v-if="paytype=='other'" class="table ">

<tbody>
    <tr>
                 <td class="bg-light-gray"><label for="Uname"><b>Your NIC | හැඳුනුම්පත් අංකය
                    </b></label></td>
                 <td > <label for="Uname" v-if="matchUser">{{ matchUser.cus_nic }}</label></td>
             </tr>
             <tr>
                 <td class="bg-light-gray"><label for="Uname"><b>Your Name | නම </b></label></td>
                 <td ><label for="Uname" v-if="matchUser">{{ matchUser.cus_name }}</label></td>
             </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Pay Amount | ගෙවන මුදල(රු.) </b></label></td>
                            <td ><label for="Uname" v-if="OtherPayData && OtherPayData[0]">{{ OtherPayData[0].other_pay_price }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු  </b></label></td>
                            <td ><label for="Uname">{{ bankfee }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Gand Total | ගෙවිය යුතු මුළු මුදල </b></label></td>
                            <td ><label for="Uname">{{ grandPayTotal}}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="rdes"><b>Description  | ගෙවීම් විස්තරය</b> </label></td>
                            <td ><label for="Uname" v-if="OtherPayData && OtherPayData[0]">{{ OtherPayData[0].other_pay_description }}</label></td>
                        </tr>   

</tbody>
</table>

<table v-if="paytype=='booking'" class="table ">

<tbody>
    <tr>
        <td class="bg-light-gray"><label for="Uname"><b>Your NIC & Name | හැඳුනුම්පත් අංකය  සහ නම </b></label></td>
        <td > <label for="Uname" v-if="matchUser">{{ matchUser.cus_nic }}-{{ matchUser.cus_name }}</label></td>
       
    </tr>
  
    <tr>
        <td class="bg-light-gray"><label for="year"><b>Applicant Name | ඉල්ලුම්කරුගේ නම</b></label></td>
        <td ><label for="Uname" v-if="bookingData && bookingData[0]"> {{ bookingData[0].name }}</label></td>  
    </tr>
    <tr>
        <td class="bg-light-gray" > <label for="month"><b>Applicant Address | ඉල්ලුම්කරුගේ ලිපිනය </b></label></td>
        <td ><label for="Uname" v-if="bookingData && bookingData[0]">{{bookingData[0].address }}</label> </td> 
    </tr>
    <tr>
        <td class="bg-light-gray" > <label for="month"><b>Applicant telephone | ඉල්ලුම්කරුගේ දුරකතන අංකය </b></label></td>
        <td ><label for="Uname" v-if="bookingData && bookingData[0]">{{bookingData[0].mobile }}</label> </td> 
    </tr>
    <tr>
        <td class="bg-light-gray"> <label for="month"><b>Request Date | ඉල්ලා සිටින දින </b></label></td>
         <td ><label for="Uname" v-if="bookingData && bookingData[0]">{{ formatDate(bookingData[0].req_date_from )}}</label> </td> 
                            
         </tr>
         <tr>
        <td class="bg-light-gray"> <label for="month"><b>Request Service | ඉල්ලා සිටින සේවාව </b></label></td>
         <td ><label for="Uname" v-if="bookingData && bookingData[0]">{{ bookingData[0].service_type }}</label> </td> 
                            
         </tr>
    <tr>
        <td class="bg-light-gray"> <label for="month"><b>Pay Amount | ගෙවන මුදල(රු.) </b></label></td>
         <td ><label for="Uname" v-if="bookingData && bookingData[0]">{{ bookingData[0].amount }}</label> </td> 
                            
         </tr>
     <tr>
        <td class="bg-light-gray"> <label for="month"><b>Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු  </b></label></td>
        <td ><label for="Uname">{{ bankfee }}</label> </td> 
                            
    </tr>
        <tr>
        <td class="bg-light-gray"> <label for="month"><b>Gand Total | ගෙවිය යුතු මුළු මුදල </b></label></td>
        <td ><label for="Uname">{{ grandPayTotal}}</label> </td> 
                            
        </tr>
           
</tbody>
</table>
<br>
     </div>
     <div v-if="paytype=='shoprent'" class="table-responsive1">
     <table class="table1">

         <tbody>
             <tr>
                             <td><b>Market/Street/Place <br> මාර්කට්/වීදිය/ස්ථානය</b></td>
                            <td><b>Shop Number <br> කඩ කාමර අංකය</b></td>
                            <td><b>Shop Rent (Rs.) <br> මාසික කුලිය  (රු.)</b></td>
                            <td><b>payable Fine Amount<br>ගෙවිය යුතු දඩ මුදල</b></td>
                            <td><b>Fine Amount<br>ගෙවන දඩ මුදල</b></td>
                            <td><b>Arrears Amount (Rs.) <br> හිඟ මුදල  (රු.)</b></td>
                            <td ><b>Total Shop Rent (Rs.) <br> මුළු මුදල  (රු.)</b></td>
             </tr>
             <tr v-for=" r in rentData" :key="r.rent_id">
                 
                     <td>{{ r.place  }}</td>
                     <td>{{r.rent_shop_no}}</td>
                     <td>{{ r.rent_price  }}</td>
                     <td>{{ r.rent_fine  }}</td>
                     <td>{{ r.pay_fine  }}</td>
                     <td>{{ r.available_arrears }}</td>
                     <td style="text-align: right;">{{ r.rent_total_amount }}</td>
            
             </tr>
             <tr>
                         <!-- <th>Total | මුළු එකතුව</th>
                            <td></td>
                            <td><b>{{ totalshoprent }}</b></td>
                            <td><b>{{ totalpayblefine }}</b></td>
                            <td><b>{{ fine }}</b></td>
                            <td><b>{{ ArrearsTotal }}</b></td>
                            <td style="text-align: right;"><b>{{total}}</b></td> -->

                            <td  colspan="2" style="text-align: left;"><b>Total | මුළු එකතුව</b></td>
                            <td><b>{{ rent_total }}</b></td>
                            <td><b>{{ totalfine }}</b></td>
                            <td><b>{{ payFinetotal }}</b></td>
                            <td><b>{{ ArrearsTotal }}</b></td>
                            <td style="text-align: right;"><b>{{total}}</b></td>
                        </tr>
                        <tr>
                            <td colspan="6" style="text-align: left;">Total Pay Amount | ගෙවන මුදල </td>
                            
                            <td style="text-align: right;"><b>{{ Totalpayamount }}</b></td>
                        </tr>
                        <tr>
                            <td colspan="6" style="text-align: left;">Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු </td>
                            
                            <td style="text-align: right;"><b>{{ bankfee }}</b></td> 
                        </tr>
                        <tr>
                            <td colspan="6" style="text-align: left;" >Gand Total | ගෙවූ මුළු මුදල </td>
                            <td style="text-align: right;"><b>{{ grandPayTotal }}</b></td>
                        </tr>
                        <tr>
                            <td colspan="6" style="text-align: left;"><b>Total Pay Arrears | ගෙවිය යුතු හිඟ කුලි මුදල </b></td>
                            <td style="text-align: right;"><b>{{ nexxtArrears }}</b></td>
                        </tr>
                        <tr>
                            <td colspan="6" style="text-align: left;"><b>Total Pay Arrears | ගෙවිය යුතු හිඟ දඩ මුදල </b></td>
                            <td style="text-align: right;"><b>{{ fineArrear }}</b></td>
                        </tr>
             
         </tbody>
     </table>
     
     <br>
 </div>


 </form><br>

</div>
</div>
</div>   
<br>
        <div class="home-banner">
            <div class="grid-banner row">
                <div v-if="user">
                    <div style="font-size: small;color:crimson; text-transform: none !important;"><b>*** ඉහතින් 
                    පෙන්වන Profile Button එක මත Click කර,ඔබගේ ප්‍රොෆයිලය පරීක්ෂා කර එහි ඔබ ලියාපදිංචි වී ඇති සභාව නොපෙන්වන්නේ නම්
                    හෝ ඔබට සේවාවන් ලබාගැනීමට අවශ්‍ය සභාවන් ඇත්නම් ඒවා සඳහා එකතු කර ගන්න.</b>
                    </div>
                    <div style="font-size: small;color:crimson; text-transform: none !important;"><b>*** 
                Click on the Profile Button shown above, check your profile and if the institute you are registered with does not appear there,
                 or if there are institutes you would like to receive services from, add them.
                 </b>
                </div>
            </div>
            <div style="font-size: small; text-transform: none !important;"><b>We are constantly trying to provide services for your convenience. In order to provide you
                 with the best service standards, elg hereby brings your own Local Authority much closer to you now!
                 24x7 Online Payment Facility
                </b>
                </div>
              <!-- <p v-if="userNic">{{ userNic }}</p> -->
            </div>
            <br> <br>
            <div class="grid-banner row">
                <div class="grid col-md-3">
                    <!-- <img src="../assets/images/dis-1.jpg" alt=""> -->
                    <div v-if="!user">
                        <!-- <span>Shop Rent |කඩ කුලී</span> -->
                        <h4>Pay your shop rent here | ඔබගේ කඩ කුලී ගෙවිම සඳහා මෙතනින් පිවිසෙන්න<br>&nbsp;</h4>
                        <router-link @click="scrollToTop()" to="/login" class="btn"><span>Shop Rent |කඩ කුලී</span></router-link>
                    </div>
                    <div v-else >
                        <div v-if="!matchshop.length" >
                                    <!-- <span>Shop Rent |කඩ කුලී</span> -->
                            <h4>Pay your shop rent here | ඔබගේ කඩ කුලී ගෙවිම සඳහා මෙතනින් පිවිසෙන්න<br>&nbsp;</h4>
                            <router-link @click="scrollToTop()" to="/thank" class="btn">Shop Rent |කඩ කුලී</router-link>
                        </div>
                        <div v-else >
                                    <!-- <span>Shop Rent |කඩ කුලී</span> -->
                            <h4>Pay your shop rent here | ඔබගේ කඩ කුලී ගෙවිම සඳහා මෙතනින් පිවිසෙන්න<br>&nbsp;</h4>
                            <router-link @click="scrollToTop()" to="/shoprentexist" class="btn">Shop Rent |කඩ කුලී</router-link>
                        </div>
                        
                    </div>
                    
                </div>

                <div class="grid col-md-3">
                    <div v-if="!user">
                        <!-- <span>Shop Rent |කඩ කුලී</span> -->
                        <h4>Pay your assesment tax here | වරිපනම් බදු ගෙවිම සඳහා මෙතනින් පිවිසෙන්න<br>&nbsp;</h4>
                        <router-link @click="scrollToTop()" to="/login"  class="btn">Assesment Tax | වරිපනම් බදු</router-link>
                    </div>
                    <div v-else >
                                    <!-- <span>Shop Rent |කඩ කුලී</span> -->
                                    <h4>Pay your assesment tax here | වරිපනම් බදු ගෙවිම සඳහා මෙතනින් පිවිසෙන්න<br>&nbsp;</h4>
                                    <router-link @click="scrollToTop()" to="/assessmenttax"  class="btn">Assesment Tax | වරිපනම් බදු</router-link>
                      
                    </div>
                    <!-- <img src="../assets/images/dis-2.png" alt=""> -->
                    <!-- <div > -->
                        <!-- <span>Assesment Tax | වරිපනම් බදු</span> -->
                        <!-- <h4>Pay your assesment tax here | ඔබගේ වරිපනම් බදු ගෙවිම සඳහා මෙතනින් පිවිසෙන්න</h4>
                        <router-link @click="scrollToTop()" to="/assessmenttax"  class="btn">Assesment Tax | වරිපනම් බදු</router-link>
                    </div> -->
                </div>

                <div class="grid col-md-3">
                    <!-- <img src="../assets/images/dis-3.jpg" alt=""> -->
                    <div v-if="!user">
                        <!-- <span>Water Bill | ජල ගාස්තු</span> -->
                        <h4>Online Booking| බැකෝ යන්ත්‍ර,ගලි බව්සර්,ක්‍රීඩාංගන,නගර ශාලාව,අදාහනාගාර මාර්ගගතව වෙන් කිරීමට පිවිසෙන්න</h4>
                        <router-link @click="scrollToTop()" to="/login" class="btn">Booking | මාර්ගගත වෙන් කිරීම්</router-link>
                    </div>
                    <div v-else>
                        <h4> Booking| බැකෝ යන්ත්‍ර,ගලි බව්සර්,ක්‍රීඩාංගන,නගර ශාලාව,අදාහනාගාර මාර්ගගතව වෙන් කිරීමට පිවිසෙන්න</h4>
                        <router-link @click="scrollToTop()" to="/onlinebooking" class="btn">Booking | මාර්ගගත වෙන් කිරීම්</router-link>
                    </div>
                </div>
                <div class="grid col-md-3">
                    <!-- <img src="../assets/images/dis-3.jpg" alt=""> -->
                    <div v-if="!user">
                        <!-- <span>Water Bill | ජල ගාස්තු</span> -->
                        <h4>Other| කඩ කුලී,වරිපනම්,මාර්ගගත වෙන් කිරීම් සඳහා අයත් නොවන වෙනත් ඕනෑම ගෙවීමක් සඳහා පිවිසෙන්න</h4>
                        <router-link @click="scrollToTop()" to="/login" class="btn">Other Payments | වෙනත් ගෙවීම්</router-link>
                    </div>
                    <div v-else>
                        <h4>Other| කඩ කුලී,වරිපනම්,මාර්ගගත වෙන් කිරීම් සඳහා අයත් නොවන වෙනත් ඕනෑම ගෙවීමක් සඳහා පිවිසෙන්න</h4>
                        <router-link @click="scrollToTop()" to="/otherpayments" class="btn">Other Payments | වෙනත් ගෙවීම්</router-link>
                    </div>
                </div>

            </div>

        </div>

       
    </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import VueBasicAlert from 'vue-basic-alert'
import html2pdf from 'html2pdf.js';
export default {
    name: "Home",
    data() {
        return {
             matchshop: [],
             Nic:'',userNic:null,receipt:'1111',grandPayTotal:0,bankfee:0,
             matchUser:undefined,
             ArrearsTotal:0,
                total:0,
                Totalpayamount:0,
                rent_total:0,
                nexxtArrears:0,
                totalfine:0,

                theSabhaData:[],
                rentData:[],
                taxData:[],
                paytype:'',
                bookingId:'',
                bookingData:[],
                payFinetotal:0,
                fineArrear:0,
                ipg:''
    // matchUserdata: [],
        }
    },
    created() {
    
        this.getSessionData();
        
       },

    computed: {
        ...mapState(["user"]),
        
    },

    methods: {
       
        ...mapMutations(["setUser"]),
        // ...mapMutations(["setshopdata"]),
       
        scrollToTop() {
            window.scrollTo(0, 0);
        
        },
        formatDate(givedate) {
      const date = new Date(givedate);
      return date.toISOString().split('T')[0];
    },
        getMonthName:function (monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber);

            return date.toLocaleString('en-US', { month: 'long' });
            },
        async getMatchshop(cusnic) {
           
                let shopdetail = (await axios.get('/shopdata/' + cusnic));
                this.matchshop=shopdetail.data
            
        },
        async MatchUser(usernic) {
            let data = await axios.get('/customersdata/' + usernic);
            this.matchUser = data.data;
        },
        async SabhaData(sCode) {
            this.theSabhaData = (await axios.get('/PraSabhadata/' + sCode)).data
        },
        calculateFine:function(fineamount) {
            let fine = 0;

            if(this.fineshopid.length==0){
            // if (!this.isGreaterThan10th) {
                fine=0

            }else{
                fine=parseFloat(fineamount)
            }
               
               
                return fine.toFixed(2);
        },
        async getSessionData(){
                window.sessionStorage.removeItem('rentDetails');
                window.sessionStorage.removeItem('shopDataid');

            const data=JSON.parse(sessionStorage.getItem('Userdata'));
            if(data){
                this.Nic=data.Unic
                await this.getMatchshop(this.Nic);
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
            }
            // if(!this.matchUser){
            // this.$refs.alert.showAlert('success', "Login successful ");   
            // }else{
            //     this.$refs.alert.showAlert('error', "Incorrect Password or NIC ");   
            // }
                    // if (this.userNic === null) {
                        let url_string = window.location.href;
                        let url = new URL(url_string);

                        this.userNic = url.searchParams.get("nic");
                        this.receipt = url.searchParams.get("receipt");
                        this.paytype = url.searchParams.get("type");
                        this.bookingId=url.searchParams.get("bId");
                        if (this.userNic !== null ) {
                             await this.MatchUser(this.userNic);
                            this.setUser(this.matchUser);
                                    if (this.receipt == null) {
                                        // this.$refs.alert.showAlert('error', "Your payment is Cancelled ");
                                    } else {
                                        // this.$refs.alert.showAlert('success', "Your payment is Successfull ");
                                    
                    
                        // this.$refs.alert.showAlert('success',"Your payment is Successfull ")
                    if(this.paytype=='shoprent'){
                    let rents= await axios.get('/shoprentbyinvoice/'+this.receipt+'/'+ this.userNic);
                    this.rentData=rents.data;
                    let Prasabha=this.rentData[0].rent_sabha_code;
                    this.theSabhaData = (await axios.get('/PraSabhadata/' + Prasabha)).data
                    // this.SabhaData(Prasabha);
                    this.ipg=this.theSabhaData[0].saba_ipg
                    let totalMonthlyShopRent = 0;
                    let Paytotal=0;
                    let totalArrears=0;
                    let fine=0;
                    let finepay=0

                for (let x = 0; x < this.rentData.length; x++) {

                    totalMonthlyShopRent += parseFloat(this.rentData[x].rent_price || 0);
                    Paytotal += parseFloat(this.rentData[x].rent_pay_amount || 0);
                    totalArrears +=parseFloat(this.rentData[x].available_arrears || 0);
                    finepay +=parseFloat(this.rentData[x].pay_fine || 0);
                    fine +=parseFloat(this.rentData[x].rent_fine || 0);
                }
                this.ArrearsTotal=totalArrears.toFixed(2);
                if(finepay>0){
                    this.total=(totalMonthlyShopRent+totalArrears+finepay).toFixed(2);
                }else{
                    this.total=(totalMonthlyShopRent+totalArrears).toFixed(2);
                }
                

                this.Totalpayamount=Paytotal.toFixed(2);
                this.rent_total=totalMonthlyShopRent.toFixed(2)
                this.nexxtArrears=(this.total-Paytotal).toFixed(2)
                this.totalfine=fine.toFixed(2);
                this.payFinetotal=finepay.toFixed(2);
                this.fineArrear=(this.totalfine-this.payFinetotal).toFixed(2)
                // this.totalshoprent=totalMonthlyShopRent.toFixed(2)
                        if(this.ipg=='BOC'){
                                this.bankfee=(parseFloat(this.Totalpayamount)*((100/(100-1))-1)).toFixed(2)
                            }else{
                                this.bankfee=(parseFloat(this.Totalpayamount)*0.01).toFixed(2)
                            }
                this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(this.Totalpayamount)).toFixed(2)

                // this.theSabhaData = (await axios.get('/PraSabhadata/' + this.rentData[0].rent_sabha_code)).data  
                
                    }
                    if(this.paytype=='tax'){
                        let arraytax= await axios.get('/taxreceipt/'+this.receipt+'/'+ this.userNic);
                        this.taxData=arraytax.data
                        let Prasabha2=this.taxData[0].tax_sabha_code;
                        this.theSabhaData = (await axios.get('/PraSabhadata/' + Prasabha2)).data
                        // this.SabhaData(Prasabha2);
                        this.ipg=this.theSabhaData[0].saba_ipg

                        let taxpayment=0

                        for (let x = 0; x < this.taxData.length; x++) {
                            taxpayment += parseFloat(this.taxData[x].tax_price || 0);
                        }
                            if(this.ipg=='BOC'){
                                this.bankfee=(parseFloat(taxpayment)*((100/(100-1))-1)).toFixed(2)
                            }else{
                                this.bankfee=(parseFloat(taxpayment)*0.01).toFixed(2)
                            }
                         this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(taxpayment)).toFixed(2)
                    }
                    if(this.paytype=='booking'){
                        let arrayBooking= await axios.get('/onbookingrequestgid/'+this.bookingId);
                        this.bookingData=arrayBooking.data
                        let Prasabha3=this.bookingData[0].sabha_code;
                        this.theSabhaData = (await axios.get('/PraSabhadata/' + Prasabha3)).data
                        // this.SabhaData(Prasabha3);
                        this.ipg=this.theSabhaData[0].saba_ipg

                        let bookingPayment=0

                        for (let i = 0; i < this.bookingData.length; i++) {
                            bookingPayment += parseFloat(this.bookingData[i].amount || 0);
                        }
                            if(this.ipg=='BOC'){
                                this.bankfee=(parseFloat(bookingPayment)*((100/(100-1))-1)).toFixed(2)
                            }else{
                                this.bankfee=(parseFloat(bookingPayment)*0.01).toFixed(2)
                            }
                         this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(bookingPayment)).toFixed(2)
                    }
                    if(this.paytype=='other'){
                        let arrayOtherPay= await axios.get('/otherpayments/'+this.userNic+'/'+this.receipt);
                        this.OtherPayData=arrayOtherPay.data
                        let Prasabha4=this.OtherPayData[0].other_pay_sabha_code;
                        this.theSabhaData = (await axios.get('/PraSabhadata/' + Prasabha4)).data
                        // this.SabhaData(Prasabha4);
                        this.ipg=this.theSabhaData[0].saba_ipg;
                        let otherPayment=0

                        for (let i = 0; i < this.OtherPayData.length; i++) {
                            otherPayment += parseFloat(this.OtherPayData[i].other_pay_price || 0);
                        }

                            if(this.ipg=='BOC'){
                                this.bankfee=(parseFloat(otherPayment)*((100/(100-1))-1)).toFixed(2)
                            }else{
                                this.bankfee=(parseFloat(otherPayment)*0.01).toFixed(2)
                            }
                        
                         this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(otherPayment)).toFixed(2)
                    }
                }
                }
           
        },
        generatePDF(receipt) {
                const element = document.getElementById(receipt);

                // Create a promise to handle the async operation
                const pdfPromise = new Promise((resolve) => {
                    html2pdf(element, {
                        margin: 1,
                        filename: 'payamentreceipt.pdf',
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'mm', format: 'a5', orientation: 'landscape' },//portrait
                    }, (pdf) => {
                        resolve(pdf.output('blob'));
                    });
                });

                // When the promise is resolved, trigger the download and open the PDF
                pdfPromise.then((blob) => {
                    const url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
              
                }).catch((error) => {
                    console.error('Error generating PDF:', error);
                });
            },
    //    
       
    },
    components: {
        VueBasicAlert,
        
    }

};
</script>

<style scoped>

.home {
    min-height: 76vh;
    
}

.home-main,
.home-about,
.home-banner,
.home-category {
    padding: 2rem 9%;
}

.home-main {
    background-image: url("../assets/images/background4.jpg");
    /* width: 100%; */
    /* height: 50%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size:cover;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: 2rem;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.home-main .content {
    padding: 2rem;
    text-align: center;
    border-radius: .5rem;
    /* background: #f7f7f7; */
    padding-left: 50rem;
    height: 27vh;
}


.home-main .content span {
    font-size: 2rem;
    color: #620a0a;
}

.home-main .content h3 {
    font-size: 3rem;
    color: #130f40;
    padding-top: .5rem;
}

.home-main .content p {
    font-size: 1.6rem;
    color: #666;
    line-height: 2;
    padding: 1rem 0;
}

.home-main .image {
    flex: 1 1 41rem;
    margin: 2rem 0;
    pointer-events: none;
}

.home-main .image .home-img {
    /* width: 100%;
    margin-top: 5rem; */
    width: 100%; /* Make the image fill its container */
    margin-top: 5rem; /* Add margin at the top */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image doesn't exceed its original size */
}

.home-main .home-parallax-img {
    position: absolute;
    top: -15rem;
    right: -15rem;
    width: 80vw;

}


/* home category */
.home-category {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1.5rem;
    padding-bottom: 5rem;
}

.home-category .box {
    padding: 2rem;
    text-align: center;
    border-radius: .5rem;
    background: #f7f7f7;
}

.home-category .box:hover {
    background: #620a0a;
}

.home-category .box:hover h3 {
    color: #fff;
}

.home-category .box img {
    height: 7rem;
}

.home-category .box h3 {
    font-size: 1.8rem;
    color: #130f40;
}


/* home banner */
.home-banner .row-banner {
    background: url(../assets/images/row-banner.png) no-repeat;
    height: 45rem;
    background-size: cover;
    background-position: center;
    position: relative;
}

.home-banner .row-banner .content {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
}

.home-banner .row-banner .content span {
    font-family: 'Satisfy', cursive;
    font-size: 2rem;
    color: #620a0a;
    color: #130f40;
}

.home-banner .row-banner .content h3 {
    font-size: 6rem;
    color: red;
    text-transform: uppercase;
}

.home-banner .row-banner .content p {
    font-size: 2rem;
    padding-bottom: 1rem;
}


.home-banner .grid-banner .grid {
    border-radius: 1rem;
    overflow: hidden;
    height: auto;
    /* height: 20rem; */
}

.home-banner .grid-banner .grid:hover img {
    transform: scale(1.2);
}

.home-banner .grid-banner .grid img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.home-banner .grid-banner .grid .content {
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
}

.home-banner .grid-banner .grid .btn {
    background: #110262;
}

.home-banner .grid-banner .grid .content.center {
    text-align: center;
    width: 100%;
}

.home-banner .grid-banner .grid .content.center span {
    color: #ca9206;
}

.home-banner .grid-banner .grid .content.center h3 {
    color: #130f40;
}

.home-banner .grid-banner .grid .content span {
    font-size: 1.5rem;
    color: #fff;
}

.home-banner .grid-banner .grid .content h3 {
    font-size: 1rem;
    color: #fff;
    padding-top: .5rem;
}

.home-about {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    background: #f7f7f7;
}

.home-about .image {
    flex: 1 1 40rem;
}

.home-about .image img {
    width: 100%;
}

.home-about .content {

    flex: 1 1 40rem;
}

.home-about .content span {
    font-family: 'Satisfy', cursive;
    font-size: 3rem;
    color: #620a0a;
}

.home-about .content .title {
    font-size: 3rem;
    padding-top: .5rem;
    color: #130f40;
}

.home-about .content p {
    padding: 1rem 0;
    line-height: 2;
    font-size: 1.4rem;
    color: #666;
}

.home-about .content .icons-container {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.home-about .content .icons-container .icons {
    flex: 1 1 20rem;
    border-radius: .5rem;
    background: #fff;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}

.home-about .content .icons-container .icons h3 {
    font-size: 1.7rem;
    color: #130f40;
}

@media (max-width: 768px) {
    #menu-btn {
        display: inline-block;
    }

    .home-main .home-parallax-img {
        top: 0;
        right: 0;
        width: 100%;
    }

    .home-banner .grid-banner .content h3 {
        font-size: 15px !important;
    }

    .home-banner .grid-banner .content.center {
        padding-left: 0px !important;
    }

}

@media (max-width: 576px) {
    .home-main .content h3 {
        font-size: 1rem;
    }

    .home-main .content p {
        font-size: 1.5rem;
    }
} 
.thank-container {
    padding: 2rem 9%;
    background: #fff;
    height: 20vh;
}


.thank-container h1 {
    width: 100%;
    /* height: 25px; */
    margin: auto;
    display: block;
    text-align: center;
    /* margin-top: 10px; */
}

.thank-container h1  span {
    position: relative;
    color: #620a0a !important;
    /* top: 40px; */
    display: inline-block;
    /* -webkit-animation: bounce 0.3s ease infinite alternate; */
    font-size: 25px;
    color: #fff;
    text-transform: none;
    /* text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
        0 5px 0 #620a0a, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
        0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4); */
}

/* .thank-container h1 p span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
}

.thank-container h1 p span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
}

.thank-container h1 p span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
}

.thank-container h1 p span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
} */

.thank-letter {
    position: relative;
    /* top: 180px; */
    font-size: 20px;
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}
.thank-letter .btn {
        background: #620a0a;
    }
.btn {
        background: #620a0a;
}
pre{
 padding-right: 5%;
 padding-top: 1%;
}
.thank-letter a {
    margin-top: 20px;
}

/* @-webkit-keyframes bounce {
    100% {
        top: -20px;
        text-shadow: 0 1px 0 #620a0a, 0 2px 0 #620a0a, 0 3px 0 #620a0a, 0 4px 0 #620a0a,
            0 5px 0 #620a0a, 0 6px 0 #620a0a, 0 7px 0 #620a0a, 0 8px 0 #620a0a, 0 9px 0 #620a0a,
            0 50px 25px rgba(0, 0, 0, 0.2);
    }
} */

@media (max-width: 1024px) {
    .thank-container {
        padding: 0px;
    }

    .thank-container h1 {
        margin-top: 20px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }
   

    .thank-letter {
        font-size: 16px;
        width: 50vw;
        top: 160px;
    }
}

@media (max-width: 576px) {

    .thank-container h1 {
        margin-top: 50px;
    }

    .thank-container h1 p span {
        font-size: 10px;
    }

    .thank-letter {
        font-size: 14px;
        width: 90vw;
        top: 90px;
    }
}
.register-container {
    padding: 1.5rem 9%;
    /* padding-top: 25rem; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    /* padding: 1rem; */
    border-radius: 0.5rem;
 
    /* animation: fadeUp 0.4s linear; */
}

.register-container .register-form-container form h3 {
    /* padding-bottom: 0.1rem; */
    font-size: 1.9rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form h4 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.6rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container form h5 {
    padding-bottom: 0.2rem;
    font-size: 1.4rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form pre {
    /* padding-bottom: 0.2rem; */
    font-size: 1.0rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: right;
    
}
.register-container .register-form-container form h6 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.25rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}
.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
   
}

.register-container .register-form-container form label {
    font-size: 1rem;
    margin: 0;
    padding: 0.6rem;
}

.register-container .register-form-container form span {
    font-size: 15px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;
}


.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}


.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}

 
/* Table */
.bg-light-gray {
    background-color: #f7f7f7;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
}

.table-responsive{
    padding-left: 3.5rem;
    width: 95%;
  /* align:center; */
}

.table-responsive1{
    padding-left: 6rem;
    /* width: 95%; */
  /* align:center; */
}
.table1{
  align-items: center;
    width: 95%;
    font-size:xx-small;
   
  /* align:center; */
}
.table1 td,
.table th {
    padding: .15rem;
    vertical-align: middle;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: auto;
    text-align: center;
} 
.table td,
.table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: 30%;
} 
.aling{
    align-items: center;
    text-align: center;
align-content: center;
/* padding-right: 15%; */
}  
</style>




