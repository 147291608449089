/* eslint-disable */  
<template>
    <section>
        <div v-if="!user" >
            <div class="register-form-container">
                <!-- <form id="head"  novalidate autocomplete="off"> -->
                    <br> <br> <br> <br> <br>
                <div class="heading">
                <span>Please Loging First.....</span>
                
                <h3><router-link class="btn dark" @click="scrollToTop()" to="/login">Login</router-link></h3>
                </div>
          
            <h3><b></b></h3>
            </div>
            
                 <!-- </form> -->
            
        </div>
        
        <div v-else  class="register-container">
        <div class="register-form-container">
 
            
            <form @submit.prevent="submitForm" id="Invoince"  novalidate autocomplete="off">
                <pre>DateTime :{{ new Date().toLocaleString()}}</pre>
                <div class="heading">
                <span>Local Government Online Payment Management System</span>
                    <h3><b>Payment Reciept</b></h3>
                 </div>
                <div  class="table-responsive">
                    <h5 v-if="theSabhaData && theSabhaData[0]">{{ theSabhaData[0].sb_address }}</h5> 
                    <h5 v-if="theSabhaData && theSabhaData[0]">Tel:{{ theSabhaData[0].sb_contact}}, Fax:{{ theSabhaData[0].fax}}</h5> 
                  <h6>Invoice No : &nbsp;{{ InvouceNum }} </h6>
                <table class="table ">
        
                    <tbody>
                        <tr>
                            <td class="bg-light-gray"><label for="Uname"><b>Your NIC | හැඳුනුම්පත් අංකය</b></label></td>
                            <td > <label for="Uname">{{ user.cus_nic }} </label></td>
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="Uname"><b>Your Name | නම</b></label></td>
                            <td ><label for="Uname">{{ user.cus_name }}</label></td>
                        </tr>
                       
                        <tr>
                            <td class="bg-light-gray"><label for="year"><b>Year | වර්ෂය</b></label></td>
                            <td ><label for="Uname">{{ year }}</label></td>  
                             
                        </tr>
                        <tr>
                            <td class="bg-light-gray"> <label for="month"><b>Month | මාසය </b></label></td>
                            <td ><label for="Uname">{{ monthName }}</label> </td> 
                            
                        </tr>
                        <tr>
                            <td class="bg-light-gray"><label for="rdes"><b>Remarks  | ගෙවීම් විස්තරය</b> </label></td>
                            <td ><label for="Uname">{{ remark }}</label></td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <div class="table-responsive1">
                <table class="table1">
        
                    <tbody>
                        <tr>
                            <td><b>Market/Street/Place <br> මාර්කට්/වීදිය/ස්ථානය</b></td>
                            <td><b>Shop Number <br> කඩ කාමර අංකය</b></td>
                            <td><b>Shop Rent (Rs.) <br> මාසික කුලිය  (රු.)</b></td>
                            <td><b>payable Fine Amount<br>ගෙවිය යුතු දඩ මුදල</b></td>
                            <td><b>Fine Amount<br>ගෙවන දඩ මුදල</b></td>
                            <td><b>Arrears Amount (Rs.) <br> හිඟ මුදල  (රු.)</b></td>
                            <td ><b>Total Shop Rent (Rs.) <br> මුළු මුදල  (රු.)</b></td>
                           
                        </tr>
                        <tr v-for=" (shops, index) in AllShopDtails" :key="index">
                            <!-- <div > -->
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{ shop.place  }}</td>
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{ shop.shop_number  }}</td>
                                <!-- <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{shop.monthly_shop_rent}}</td> -->
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{getPaymentOfMonth(shop.pay_month,shop.monthly_shop_rent) }}</td>
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{ shop.fine }}</td>
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{ calculateFine(shop.fine) }}</td>
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex">{{DiffOfMonths(shop.pay_month,shop.monthly_shop_rent,shop.arrears)}}</td>
                                <td v-for=" (shop, innerIndex) in shops" :key="innerIndex" style="text-align: right;">{{calculateTotal(calculateFine(shop.fine),DiffOfMonths(shop.pay_month,shop.monthly_shop_rent,shop.arrears),getPaymentOfMonth(shop.pay_month,shop.monthly_shop_rent)) }}</td>
                            <!-- </div> -->
                        </tr>
                        <tr>
                            <th>Total | මුළු එකතුව</th>
                            <td></td>
                            <td><b>{{ totalshoprent }}</b></td>
                            <td><b>{{ totalpayblefine }}</b></td>
                            <td><b>{{ fine }}</b></td>
                            <td><b>{{ ArrearsTotal }}</b></td>
                            <td style="text-align: right;"><b>{{total}}</b></td>
                        </tr>
                        <tr>
                            <th>Total Pay Amount | ගෙවන මුදල </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                           
                            <td style="text-align: right;"><b>{{ Totalpayamount }}</b></td>
                        </tr>
                        <tr>
                            <th>Banking Service Charge | බැංකුව මගින් අය කරනු ලබන සේවා ගාස්තු </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                            <td style="text-align: right;"><b>{{ bankfee }}</b></td> 
                            
                        </tr>
                        <tr>
                            <th>Gand Total | ගෙවිය යුතු මුළු මුදල </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                            <td style="text-align: right;"><b>{{ grandPayTotal }}</b></td>
                        </tr>
                    </tbody>
                </table>
                
                
            </div>
            <br>
            <div class="table-responsive">  
                <h6><input @click="btnenable"  type="checkbox" id="checkid" v-model="checkVal"  >&nbsp;I certify that the above information is true and correct.| ඉහත තොරතුරු සත්‍ය හා නිවැරදි බවට සහතික කරමි. </h6>
                </div>
                <div class="form-group aling"  v-if="theSabhaData && theSabhaData[0] && theSabhaData[0].saba_ipg=='BOC'">
                <button class="btn " id="payboc"  type="submit" :disabled="disableButton">Pay Now</button>
                </div>
            </form>
            <br>
            
                <div v-html="form" id="formResponse" class="form-group aling" style="display: none;"></div><br>
        </div>
    </div>
</section>
</template>
<script>




// import router from "@/router";
import axios from "axios";
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {
   
    name: "ShoprentInvocice",
  
    data() {
        return {
                   
                        // Sname:'scsad',
                        total:0,Totalpayamount:0,ArrearsTotal:0,grandPayTotal:0,bankfee:0,
                        cusName:'',Sname:'',
                        monthName:'',
                        checkVal:'',
                        sessionData:'',
                        InvouceNum:'',sabha_code:'',remark:'',price:'',month:'',year:'',
                        pay_head:0,
                        nextid:'',
                        Nic:'',
                        payamount:{},
                        theSabhaData:[],
                        matchUser:undefined,
                        matchPaymonth:[],
                        onlinPaydata:[],
                        shopDeID:[],
                        temp:[],
                        AllShopDtails:[],
                        postData: {
                            mid:'',
                            paytype:'',
                            amount: '',
                            referenceNumber: '',
                            email:'',
                            bill_to_forename:'',
                            bill_to_surname:'',
                            bill_to_address_line1:'',
                            bill_to_address_line2:'',
                            bill_to_address_city:'',
                            bill_to_address_state:'',
                            bill_to_address_postal_code:'',
                            bill_to_address_country:'LK',
                        },
                        response: null,
                        error: null,
                        currentDate: new Date(),
                        // date:new Date(),
                       fine:0,totalshoprent:0,
                       vote:'',
                       fineshopid:[],
                       payblefine:0,
                       totalpayblefine:0,
                       fineVal:0,
                       fineVote:'',
                       formData: {
                            boc_sid : '',
                            boc_paytype: '',
                            boc_amount: '',
                            boc_grandamount:'',
                            boc_cus_nic: '',
                            boc_invoice: '',
                            boc_remarks:'',
                            country:'',
                            street :'',
                            street2 :'',
                            city :'',
                            state :'',
                            zip :'',

                        },
                        ipg:'',
                        disableButton:true,
                      
        
                        
                       
        }
    },

        created() {
            this.getSessionData();
        //    this.LoadData();
           this.ShowMonthName();
        //    this.getshopdata();
        },
        computed : {
            ...mapState(["user"]),

                isGreaterThan10th() {
                return this.currentDate.getDate() > 10;
                },
         },
         
    methods: {
        ...mapMutations(["setUser"]),

    //     formattedDate: function() {
    //     this.currentDate=new Date();
    //      const day = this.currentDate.getDate().toString().padStart(2, "0");
    //      const month = (this.currentDate.getMonth() + 1)
    //        .toString()
    //        .padStart(2, "0");
    //      const year = this.currentDate.getFullYear().toString();
    //      var defaultdate =`${year}-${month}-${day}`
    //     //  return `${year}-${month}-${day}`;
    //     return defaultdate
    //    },
    formattedDate() {
           
           let date = new Date()
           const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, '0');
     const day = String(date.getDate()).padStart(2, '0');
   const fdate=`${year}-${month}-${day}`;
   const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
   return fdate.toString('en-US', options);
   //   return `${year}-${month}-${day}`;
         },
        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
       
        calculateTotal:function(x,y,z) {
                let totalAmountshop = 0;

                totalAmountshop=parseFloat(x)+parseFloat(y)+parseFloat(z)
               
                return totalAmountshop.toFixed(2);
                },

        calculateFine:function(fineamount) {
            let fine = 0;

            if(this.fineshopid.length==0){
            // if (!this.isGreaterThan10th) {
                fine=0

            }else{
                fine=parseFloat(fineamount)
            }
               
               
                return fine.toFixed(2);
        },
        DiffOfMonths: function(pm, cr,curAre) {
                  // this.curArrears = cr;
                  let currentMonth = this.currentDate.getMonth();
                  let currentYear = this.currentDate.getFullYear();

                   let currentYearMonth = currentYear * 12 + currentMonth;
                   let pmInCurrentYear = (currentYear - 1) * 12 + pm;
             if (pm <= currentMonth) {
                  pmInCurrentYear = (currentYear - 1) * 12 + (pm + 12);
                  }
             if (pm === currentMonth) {
                return 0;
              }

// Calculate the absolute difference in months
            let monthDiff = Math.abs(currentYearMonth - pmInCurrentYear);

                 this.curArrears = ((parseFloat(cr) * monthDiff)-parseFloat(cr))+parseFloat(curAre);
               //  return monthDiff
                  return this.curArrears.toFixed(2);
               },
        getPaymentOfMonth:function( paymonth1,rent) {

            let Mpayment = parseFloat(rent);

                if (isNaN(Mpayment)) {
                    // // Handle the case where rent is not a valid number
                    // console.error("Invalid monthly rent:", rent);
                    return 0; // Return 0 or any default value based on your requirements
                }

                if (paymonth1 == this.currentDate.getMonth()) {
                    Mpayment = 0;
                }

                return Mpayment.toFixed(2);
            
            // let Mpayment =rent;
            //       if (paymonth1== this.currentDate.getMonth()) {
            //       Mpayment=0
            //       }
            //       return Mpayment
         },
       async getSessionData(){
        
        const data=JSON.parse(sessionStorage.getItem('rentDetails'));
        this.fineshopid=JSON.parse(sessionStorage.getItem('shopfineid'));
            if(data){
                // this.cus_nic=data.cus_nic
                this.sabha_code=data.sabha_code
                this.remark=data.remark
                this.month=data.month
                this.year=data.year
                this.InvouceNum=data.invoiceNum
                // this.payamount=data.payamount

            }
            this.sessionData=data;
            this.payamount=JSON.parse(sessionStorage.getItem('payamounts'));
            const nicdata=JSON.parse(sessionStorage.getItem('Userdata'));
            if(nicdata){
                this.Nic=nicdata.Unic
            }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
           
        if(this.sessionData){
            this.theSabhaData = (await axios.get('/PraSabhadata/' + this.sabha_code)).data
            this.Sname='shoprent'
            this.ipg= this.theSabhaData[0].saba_ipg

            // this.AllShopDtails = (await axios.get('/shopdata/'+this.shopDeID )).data;
            let datau = await axios.get('/customersdata/' + this.user.cus_nic);
            this.matchUser = datau.data;
            this.cusName=this.matchUser.cus_name;
            // this.onlinPaydata= (await axios.get('/genaratenum/')).data;
            // lastGennum=parseInt(this.onlinPaydata[0].gen_num);
           
            // this.InvouceNum= (this.sabha_code.concat(this.month,this.year,lastGennum));
            // // this.InvouceNum='PRS571120232'
            // this.nextid= lastGennum+1;

            //         const genInvNum = {
            //             gen_num:this.nextid,
            //        }
            // await axios.put('/genaratenum/',genInvNum);
            this.shopDeID=JSON.parse(sessionStorage.getItem('shopDataid'));
           
           for(let i=0; i<this.shopDeID.length; i++){ 
               this.temp=(await axios.get('/shopdataid/'+ this.shopDeID[i])).data
               // this.a.push(this.temp.monthly_shop_rent)
                this.AllShopDtails.push(this.temp);
                
               //  this.test[i]=this.AllShopDtails[i].monthly_shop_rent[i];  
           }
           if(this.user){
           let totalMonthlyShopRent = 0;
           let Paytotal=0;
           let totalArrears=0;
           
              let totalfine=0
            let monthly_rent=0
            let totalWithArr=0
            let payfinetotal=0
            let fineArr=0
            let totalFineArr=0
       
            //     for(let a=0;a<this.payamount.length;a++){
            //         Paytotal += parseFloat(this.payamount[a] || 0);
            //     }
            //  this.Totalpayamount=Paytotal.toFixed(2);
             //    this.payamount=Paytotal.toFixed(2);

               for (let x = 0; x < this.AllShopDtails.length; x++) {

               const innerArray = this.AllShopDtails[x];
               for (let j = 0; j < innerArray.length; j++) {
                const shop = innerArray[j];

                        let monthRent = shop.monthly_shop_rent;
                        let pmonth=shop.pay_month;
                
                      let checkMonth = this.getPaymentOfMonth(pmonth, monthRent);
                        if(checkMonth==0){ 
                            totalMonthlyShopRent =0;
                                monthly_rent=0;
                            }
                            else{
                                totalMonthlyShopRent += parseFloat(shop.monthly_shop_rent || 0);
                                monthly_rent=shop.monthly_shop_rent
                                
                            }
                           
                    // if (!this.isGreaterThan10th) {
                    //     // this.monthlyRent=parseFloat(shop.monthly_shop_rent);
                    
                    // } else {
                        this.fineVal=this.calculateFine(shop.fine);
                        // this.monthlyRent=parseFloat(shop.monthly_shop_rent)+fineVal;
                    // }
                   
                   Paytotal += parseFloat(this.payamount[shop.shop_details_id] || 0);
                   totalfine+=parseFloat(this.fineVal|| 0)
                   this.payblefine=shop.fine
                   payfinetotal+=parseFloat(this.payblefine|| 0)
                   this.totalpayblefine=payfinetotal.toFixed(2)
                //    totalMonthlyShopRent += parseFloat(this.monthlyRent || 0);
                let todayarrears= this.DiffOfMonths(pmonth,monthRent,shop.arrears)
                   totalArrears+=parseFloat(todayarrears || 0);

                   this.ArrearsTotal=totalArrears.toFixed(2);
                this.total=this.calculateTotal(totalMonthlyShopRent,totalArrears,totalfine);
                if( this.fineVal>0){
                    this.Totalpayamount=(parseFloat(Paytotal)+parseFloat(this.fineVal)).toFixed(2);
                }
                else{
                    this.Totalpayamount=Paytotal.toFixed(2);
                }

               
                this.rent_total=totalMonthlyShopRent.toFixed(2)
                this.fine=totalfine.toFixed(2)
                this.totalshoprent=totalMonthlyShopRent.toFixed(2)
                if(this.ipg=='BOC'){
                this.bankfee=(parseFloat(this.Totalpayamount)*((100/(100-1))-1)).toFixed(2)

                }else{
                 this.bankfee=(parseFloat(this.Totalpayamount)*0.01).toFixed(2)
                 }
                this.grandPayTotal=(parseFloat(this.bankfee)+parseFloat(this.Totalpayamount)).toFixed(2)
                totalWithArr=parseFloat(this.totalshoprent)+parseFloat(this.ArrearsTotal)
                
                //  this.grandPayTotal=parseFloat(this.Totalpayamount).toFixed(2)
                            



                   const datarent = {
                       rent_cus_nic:this.user.cus_nic,
                       rent_invoice_num :this.InvouceNum,
                       rent_sabha_code:this.sabha_code,
                       rent_shop_place:shop.shop_place,
                       rent_shop_no: shop.shop_number,
                       rent_description: this.remark,
                       rent_price:monthly_rent,
                       rent_month:this.month,
                       rent_year:this.year,
                       rent_fine:this.payblefine,
                       rent_pay_amount:this.payamount[shop.shop_details_id],
                       available_arrears:totalArrears,
                       rent_with_arrears:totalWithArr,
                       rent_total_amount:this.total,
                       income_head_id:shop.rent_head,
                       pay_fine:this.fineVal,
                                           
                   }
                   await axios.post("/shoprentstemp/",datarent);
             
                   if(this.fineVal>0){
                    fineArr=(parseFloat(this.payblefine)-parseFloat(this.fineVal)).toFixed(2)
                totalFineArr=(parseFloat(this.payblefine)+parseFloat(fineArr)).toFixed(2)
                    const datarentfine = {
                       rent_cus_nic:this.user.cus_nic,
                       rent_invoice_num :this.InvouceNum,
                       rent_sabha_code:this.sabha_code,
                       rent_shop_place:shop.shop_place,
                       rent_shop_no: shop.shop_number,
                       rent_description: this.remark,
                       rent_price:monthly_rent,
                       rent_month:this.month,
                       rent_year:this.year,
                       rent_fine:this.payblefine,
                       rent_pay_amount:this.fineVal,
                       available_arrears:this.payblefine,
                       rent_with_arrears:totalFineArr,
                       rent_total_amount:this.total,
                       income_head_id:shop.fine_vote,
                       pay_fine:this.fineVal,
                                         
                   }
                   await axios.post("/shoprentstemp/",datarentfine);
                   }
                   // Convert 'Monthly_shop_rent' to a number and add it to the total
                //    totalMonthlyShopRent += parseFloat(shop.monthly_shop_rent || 0);
                //    totalArrears+=parseFloat(shop.arrears || 0);
                //    Paytotal += parseFloat(shop.rent_pay_amount || 0);
                this.vote=shop.rent_head
                this.fineVote=shop.fine_vote
               }
               
               }
               
                
           const onlineData = {
                   online_cus_nic:this.user.cus_nic,
                   online_sabha_code:this.sabha_code,
                   online_invoice_num:this.InvouceNum,
                   online_pay_amount:this.Totalpayamount,
                   online_pay_month:this.month,
                   online_pay_year:this.year,
                   online_pay_des:this.remark,
                   pay_type:'shoprent',
                   vote:this.vote,
                   online_pay_date:this.formattedDate()
                   
               // ... other key-value pairs
                   };
                   await axios.post("/onlinepaymenttemp/",onlineData);

           }
                  

            this.postData.mid=this.sabha_code,
            this.postData.paytype='shoprent',
            this.postData.amount=this.grandPayTotal,
            this.postData.referenceNumber=this.InvouceNum,
            this.postData.email=this.user.cus_email,
            this.postData.bill_to_forename=this.user.cus_nic,
            this.postData.bill_to_surname=this.user.cus_name,
            this.postData.bill_to_address_line1=this.user.cus_address,
            this.postData.bill_to_address_line2=this.user.cus_address,
            this.postData.bill_to_address_city=this.Sname,
            this.postData.bill_to_address_country='LK',
            
      axios
        .post('/process-payment', this.postData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.response = response.data;
        //   alert("response");
        //   alert(response.data);
          document.getElementById('formResponse').innerHTML=response.data;
        //   document.getElementById('submit').style.display="none";
        })
        .catch((error) => {
          this.error = error;
        }); 
    }else{
        // this.$router.push("/shoprentexist");
        this.$router.push('/shoprentexist');
    }

        },
        
        scrollToTop() {
            window.scrollTo(0, 0);
        },
     
        
        getMonthName:function (monthNumber) {
            const date = new Date();
            date.setMonth(monthNumber);

            return date.toLocaleString('en-US', { month: 'long' });
            },

        ShowMonthName(){
            this.monthName=this.getMonthName(this.month);
        },
        btnenable(){
            if(this.checkVal==true){
                document.getElementById('formResponse').style.display='none'
                this.disableButton = true;
                // let submitButton = document.getElementById('payboc');
                // submitButton.disabled = true;
                // document.getElementById('payboc').disabled=true


            }else{
                document.getElementById('formResponse').style.display='block'
                // document.getElementById('payboc').disabled=false
                this.disableButton = !this.disableButton;
            }
        }, 
        
        submitForm() {
            this.formData.boc_sid =this.sabha_code
                            this.formData.boc_paytype='shoprent'
                            this.formData.boc_amount=this.Totalpayamount
                            this.formData.boc_grandamount=this.grandPayTotal
                            this.formData.boc_cus_nic=this.user.cus_nic
                            this.formData.boc_invoice=this.InvouceNum
                            this.formData.boc_remarks=this.remark
                            this.formData.country='LKA'
                            this.formData.street ='25466'
                            this.formData.street2 =this.Sname
                            this.formData.city ='25466'
                            this.formData.state ='25466'
                            this.formData.zip ='25466'   

        // console.log("Response");
    //   axios.post('http://localhost:3000/api/bocpay/', this.formData)
    const storedBackendUrl =
  window.sessionStorage.getItem("backendUrl") ||
  "https://elgservices.lk/api";

      axios.post(storedBackendUrl+'/bocpay/', this.formData)
        .then(response => {
        // window.location.href = "https://test-bankofceylon.mtf.gateway.mastercard.com/checkout/pay/"+response.data.sessionid+"?checkoutVersion=1.0.0"   // test url
        window.location.href = "https://bankofceylon.gateway.mastercard.com/checkout/pay/"+response.data.sessionid+"?checkoutVersion=1.0.0" // production url

          console.log(response.data.sessionid);
        })
        .catch(error => {
          console.error(error);
        });
    },
  

    }
}
</script>





<style scoped>
.register-container {
    padding: 1.5rem 9%;
    min-height: 80vh;
   background-image: url("../assets/images/regbacground.jpg");
  background-repeat: no-repeat;
  background-size:cover;
}

.register-container .register-form-container {
    background: #ffffffe9;

}

/* .register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
} */

.register-container .register-form-container form h3 {
    /* padding-bottom: 0.1rem; */
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form h4 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container form h5 {
    padding-bottom: 0.2rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}

.register-container .register-form-container  pre {
    /* padding-bottom: 0.2rem; */
    font-size: 1.0rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: right;
    padding-top: 1%;
    padding-right: 2%;
    
}
.register-container .register-form-container form h6 {
    /* padding-bottom: 0.2rem; */
    font-size: 1.25rem;
    /* text-transform: uppercase; */
    color: #130f40;
    /* margin: 0; */
    text-align: left;
    
}
.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    /* background: #f7f7f7; */
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
   
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0.6rem;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #620a0a;
}


.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    
}


.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}

 .heading{
    padding: 0;
 }
/* Table */
.bg-light-gray {
    background-color: #f7f7f7;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 0px solid #dee2e6;
}

.table-responsive{
    padding-left: 3.5rem;
    width: 95%;
  /* align:center; */
}

.table-responsive1{
    padding-left: 8rem;
    /* width: 95%; */
  /* align:center; */
}
.table1{
  align-items: center;
    width: 90%;
    font-size:small;
   
  /* align:center; */
}
.table1 td
 {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    width: auto;
    text-align: center;
} 
.table td,
.table th {
    padding: .25rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    width: 30%;
} 
.aling{
    align-items: center;
    text-align: center;
align-content: center;
/* padding-right: 15%; */
}  
</style>
