<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    
    <section >
        <div class="register-container">
        <div class="register-form-container">
            <form id="editProfile"  novalidate autocomplete="off">
                <div class="heading">
            <span>Local Government Online Payment Management System</span>
            <h3>Edit Profile Details</h3>
        </div>
        <h3> Persional Information </h3>
        <div class="row">
                <div class="form-group col-sm-6">
                    <label for="uName">Name</label> 
                    <input type="text" name="uName" id="uName" v-model="proObj.name" class="form-control">
                    <p v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
                    
                </div>
            
                <div class="form-group col-sm-6">
                    <label for="uPhone">Phone number</label>
                    <input type="text" name="uPhone" id="uPhone" v-model="proObj.phone" class="form-control">
                    <p v-if="errorObj.phoneErr.length > 0">{{ errorObj.phoneErr[0] }}</p>
                    
                </div>
                
             </div>   
             <div class="row">
             <div class="form-group col-sm-6">
                    <label for="uAddress">Address</label>
                    <input type="text" name="uAddress" id="uAddress" v-model="proObj.address" class="form-control">
                    <p v-if="errorObj.addErr.length > 0">{{ errorObj.addErr[0] }}</p>
                </div>
                <div class="form-group col-sm-6">
                    <label for="uEmail">Email</label>
                    <input type="email" name="uEmail" id="uEmail" v-model="proObj.email" class="form-control">
                    <p v-if="errorObj.emailErr.length > 0">{{ errorObj.emailErr[0] }}</p>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-sm-5">
                    </div>
                <div class="form-group col-sm-4">
                    <input type="button" value="Update" class="btn" @click="handleSubmit" />
                   
                </div>
                </div>
                <br>
                <div class="heading"><h3> Local Councils Details </h3></div>
                <h3> Registered local councils to access your services </h3>
                <div class="row">
   <!-- Table -->
   <table class="table table-striped mt-4" v-if="prasabha.length">
      <thead>
        <tr>
          <th>#</th>
          <th>Sabha Name</th>
          <th>Contact</th>
          <!-- <th>Fax</th>
          <th>Email</th>
          <th>Address</th> -->
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sabha, index) in prasabha" :key="sabha.sb_code">
          <td>{{ index + 1 }}</td>
          <td>{{ sabha.sb_name_en }}</td>
          <td>{{ sabha.sb_contact }}</td>
          <!-- <td>{{ sabha.fax }}</td>
          <td>{{ sabha.sb_email }}</td>
          <td>{{ sabha.sb_address }}</td> -->
          <td> <input
            type="button"
            value="Remove"
            class="fa-edit"
            @click="removeSabha(sabha.sb_code)"
          /></td>
        </tr>
      </tbody>
    </table>

    <!-- No Data Message -->
    <div v-else class="mt-4 text-center">
      <p>No data available for the selected Sabha.</p>
    </div>
                </div>
                <h3> Add a new local council to your profile </h3>
                <div class="row">
             <div class="form-group col-sm-3">
                    <label id="uProvince"><b>Province:</b>
                    </label>
                       
                           <select  @change="changeprovince"   v-model="proObj.pro"  name="province" id="province" class="form-control">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>

                    
                </div>
            
                <div class="form-group col-sm-3">
                    <label id="uDistrict"><b>District:</b>
                    </label>
                    
                            <select class="form-control" @change="changedist" name="district" id="district" v-model="proObj.dis" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ds in Districts" :key="ds.dist_code" :value="ds.dist_code">{{ ds.dist_name }} </option>
                            </select>
                                
                          
                                <!-- <p><span>User country: {{ Prs.praddress.DistVal }}</span></p> -->
                    <p class="error-mess" v-if="errorObj.disErr.length > 0">{{ errorObj.disErr[0] }}</p>
                    
                </div>

                <div class=" form-group col-sm-3">
                    <label id="uPradeshiya"><b>Pradeshiya Sabha:</b>
                    </label>
                    
                            <select  class="form-control" name="pradeshiya" id="pradeshiya" v-model="proObj.pra_sabha" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ps in p_sabha" :key="ps.sb_code" :value="ps.sb_code">{{ ps.sb_name_en}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ ps.sb_name_sin }} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.praErr.length > 0">{{ errorObj.praErr[0] }}</p>
                               
                </div>
                <div class=" form-group col-sm-3">
                    <br>
                    <input type="button" value="Add New" class="btn" @click="handlesabha" />
                </div>
                
            </div>  
            </form>
        </div>
    </div>
    </section>
  
</template>

<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
import { mapState, mapMutations } from "vuex";
export default {
    name: "Profile",

    data() {
        return {
            proObj: { pro:"",dis:"",pra_sabha:"",name: "", phone: "", address: "", email: "" },
            errorObj: { disErr: [],proErr: [], praErr: [],nameErr: [], phoneErr: [], addErr: [], emailErr: []},
            matchProfile:undefined,
            matchUser:undefined,
            theProfile:[],
           cus_nic:'',Nic:'',
           p_sabha:[],
            Districts: [],
            province: [],
            prasabha:[],
        }
    },

   
        
    computed: {
        ...mapState(["user"])
    },
    created(){
        // this.getSessionData()
        this.showAUser()
        this.getSessionData();
        this.AllDistricts();
           this.Allprovince();
           this.AllprSabha();
           this.AllregisterprSabha();
    }, 
    methods: {
        ...mapMutations(["setUser"]),
        async MatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },
        async getSessionData(){
    
        const data=JSON.parse(sessionStorage.getItem('Userdata'));
        if(data){
            this.Nic=data.Unic
        }
            await this.MatchUser(this.Nic);
            this.setUser(this.matchUser);
        },
        async showAUser() {
        // let data = this.user.cus_nic;
        this.theProfile= (await axios.get('/customersdata/' +this.user.cus_nic)).data;
        

        this.proObj.name = this.theProfile.cus_name
        this.proObj.phone = this.theProfile.cus_contact
        this.proObj.address = this.theProfile.cus_address
        this.proObj.email = this.theProfile.cus_email
        
        
     
   },
   async Allprovince() {
           
           this.province = (await axios.get('/provinces/' )).data;
          
          },
          async AllDistricts() {
           this.Districts = (await axios.get('/districts/')).data;
           
          },
          async AllprSabha() {
           this.p_sabha = (await axios.get('/Prasabha/' )).data;
    
          },
          async changeprovince() {

                let DistrictsData = (await  axios.get('/districts/' +this.proObj.pro));
                this.Districts=DistrictsData.data

                },
                async changedist() {

                let SabhaData = (await axios.get('/Prasabha/' + this.proObj.dis));
                this.p_sabha=SabhaData.data;

                }, 
    async AllregisterprSabha() {
  try {
    const allsabhaResponse = await axios.get('/customersabha/' + this.Nic);
    const allsabha = allsabhaResponse.data;

    if (allsabha && allsabha.length > 0) {
      this.prasabha = [];

      for (const sabha of allsabha) {
        if (sabha.sabha) {
          const psabhaResponse = await axios.get('/PraSabhadata/' + sabha.sabha);
          this.prasabha.push(...psabhaResponse.data);
        }
      }

      // console.log('All Sabha Data:', this.p_sabha);
    } else {
      console.error('No sabha found for this NIC.');
    }
  } catch (error) {
    console.error('Error fetching sabha data:', error);
  }
},
async removeSabha(sb_code) {
      try {
        // Optional: Send a request to the server to delete the sabha
        await axios.delete(`/deletecussabha/${sb_code}`);

        // Update the frontend by filtering out the removed sabha
        this.prasabha = this.prasabha.filter((sabha) => sabha.sb_code !== sb_code);

        // Show a success message
        this.$refs.alert.showAlert('success', 'Sabha removed successfully!');
      } catch (error) {
        // Handle errors
        this.$refs.alert.showAlert('error', 'Failed to remove sabha. Please try again.');
        console.error(error);
      }
    },
   async updateProfileMatch(id) {
           
           let data = await axios.get('/customersdata/' + id );
           this.matchProfile = data.data;
       },

        resetCheckErr: function () {
            this.errorObj.nameErr = [];
            this.errorObj.phoneErr = [];
            this.errorObj.addErr = [];
            this.errorObj.emailErr = [];
            
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            // Name validate
            // if (!this.proObj.name) {
            //     this.errorObj.nameErr.push("Entering a name is required");
            // }
            // else {
            //     if (!/^[A-Za-z]+$/.test(this.proObj.name.replace(/\s/g, ""))) {
            //         this.errorObj.nameErr.push('A name can only contain letters');
            //     }
            // }

            // // Phone validate
            // if (!this.proObj.phone) {
            //     this.errorObj.phoneErr.push('Entering phone number is required');
            // }
            // else {
            //     // if (!this.proObj.phone.startsWith('94')) {
            //     //     this.errorObj.phoneErr.push('Phone numbers must start with 84');
            //     // }
            //     if (this.registerObj.phone.length != 11) {
            //         this.errorObj.phoneErr.push('Phone numbers must have exactly 11 digits');
            //     }

            //     if (!/[0-9]{11}/.test(this.registerObj.phone)) {
            //         this.errorObj.phoneErr.push('Phone numbers can only contain numbers');
            //     }
            // }
            // // Address Validate
            // if (!this.proObj.address) {
            //     this.errorObj.addErr.push("Entering a name is required");
            // }

            // // Email validate
            // if (!this.proObj.email) {
            //     this.errorObj.emailErr.push("Entering a email is required");
            // }
            // else {
            //     if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(this.proObj.email)) {
            //         this.errorObj.emailErr.push('Email must be valid');
            //     }
            // }

            if (!this.proObj.pro) {
                this.errorObj.proErr.push('Province is required');
                }
                if (!this.proObj.dis) {
                this.errorObj.disErr.push('District is required');
                }
                if (!this.proObj.pra_sabha) {
                this.errorObj.praErr.push('Sabha is required');
                }
           
          
        },
      
        async handleSubmit() {
            // this.checkForm();

            // if (!this.checkEmptyErr()) {
            //     e.preventDefault();
            // } else {
                // e.preventDefault();
                if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                        this.$router.push("/login");
                    }
                    else{
                await this.updateProfileMatch(this.user.cus_nic);
                    if(!this.matchProfile){
                       
                           this.$refs.alert.showAlert("Something went wrong");
                           
                    } 
                    else{
                        let data = {
                            cus_name: this.proObj.name,
                            cus_contact: this.proObj.phone,
                            cus_address: this.proObj.address,
                            cus_email: this.proObj.email,
                           
                        };
                    await axios.put("/customersupdate/" + (this.user.cus_nic),data,)
                   
                        this.$refs.alert.showAlert('success', 'Successfully Updated !');
                  
                       
                    }
                }

     
        //    / }
        },

        async handlesabha() {
            
                if(!this.user){
                        this.$refs.alert.showAlert('error','Please Loging to Continue !')
                        this.$router.push("/login");
                    }
                    else{
                await this.updateProfileMatch(this.user.cus_nic);
                    if(!this.matchProfile){
                       
                           this.$refs.alert.showAlert("Something went wrong");
                           
                    } 
                                        if (!this.proObj.pro || !this.proObj.dis || !this.proObj.pra_sabha || !this.Nic) {
                            // Show an error message if any field is empty
                            this.$refs.alert.showAlert('error', 'Please fill out all required fields.');
                            return;
                        }

                        try {
                            // Prepare data to send
                            let datasabha = {
                            province: this.proObj.pro,
                            district: this.proObj.dis,
                            sabha: this.proObj.pra_sabha,
                            cus_nic: this.Nic,
                            };

                            // Send data to the server
                            await axios.post("/addcustomersabha/", datasabha);

                            // Show success message
                            this.$refs.alert.showAlert('success', 'Successfully Added!');

                            // Reset form fields
                            this.proObj.pro = "";
                            this.proObj.dis = "";
                            this.AllregisterprSabha();
                            // this.Nic = "";
                        } catch (error) {
                            // Handle errors from the server or network
                            this.$refs.alert.showAlert('error', 'Failed to add data. Please try again.');
                            console.error(error);
                        }
                    }
                }

        
    },

    components: {
        VueBasicAlert
    }

}
</script>

<style scoped>
table {
  width: 100%;
  margin-top: 20px;
  font-size: small;
}
.text-center {
  color: #777;
}
.fa-edit{
    color: #fafafa;
    background-color: #b11313;
    width: 60px;
}
.order-section {
    padding: 2rem 9%;
}

.order-section .icons-container {
    display: grid;
  
    grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
    gap: 1.5rem;
    margin-bottom: 2rem;
}

.order-section .icons-container .icons {
    border-radius: .5rem;
    padding: 2rem;
    text-align: center;
    background: #f7f7f7;
}

.order-section .icons-container .icons img {
    height: 10rem;
}

.order-section .icons-container .icons h3 {
    font-size: 2rem;
    color: #130f40;
    margin-top: .5rem;
}

.order-section form {
    background: #f7f7f7;
    padding: 2rem;
    border-radius: .5rem;
}

.order-section form .row {
    justify-content: space-between;
}

.order-section form .row .input-box {
    width: 49%;
    padding: 1.8rem 0;
}

.order-section form .row label {
    font-size: 1.7rem;
    color: #666;
}

.order-section form .row p {
    font-size: 1.5rem;
    position: absolute;
    color: rgb(243, 47, 47);
    margin: 0;
    padding-top: 5px;
}

.order-section form .row input,
.order-section form .row textarea {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem 1.2rem;
    width: 100%;
    border-radius: .5rem;
    font-size: 1.6rem;
    text-transform: none;
    color: #130f40;
}

.order-section form .row textarea {
    height: 20rem;
    resize: none;
}

.order-section form .row .map {
    height: 100%;
    width: 100%;
    border-radius: .5rem;
}

@media (max-width: 768px) {
    .order form .row .input-box {
        width: 100%;
    }

    .order-section form .row {
        display: block;
        max-width: 100%;
        width: 100%;
        margin: 0;
    }

    .order-section form .row .input-box {
        width: 100%;
    }

}

@media (max-width: 576px) {
    .order-section .icons-container {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    }
}


.register-container {
    /* padding: 1.5rem 9%;
    background-image: url("../assets/images/regbacground.jpg");
     min-height: 80vh; */

     min-height: 77vh;
     padding-top: 1rem;
       background-image: url("../assets/images/regbacground.jpg");
    width: 100%;
    /* height: 50%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size:cover;
  padding-left: 3%;
}

.register-container .register-form-container {
    background: #ffffffeb;
    width: 95%;
}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    padding-left:5rem;
    padding-right: 5rem;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.3rem;
    font-size: 1.9rem;
    text-transform:none;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #ffffff;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: none;
    
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    padding-left: 5px;
    padding-right: 40px;
    color: #620a0a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 40%;
    text-align: center;
    align-items: center;
    background: #620a0a;
    font-size: 2cap;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #620a0a;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

}
</style>