<template>
    <section>
    
    <div class="register-container">
        <div class="register-form-container">
            <form id="registerForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
            <span>Local Government Online Payment Management System</span>
            <h3>Create Your Account</h3>
        </div>
            <div class="row">
             <div class="form-group col-sm-4">
                    <label id="uProvince"><b>Select Province:</b>
                    </label>
                       
                           <select  @change="changeprovince"   v-model="registerObj.pro"  name="province" id="province" class="form-control">
                                <option value="" selected disabled>Choose</option>
                                <option v-for="f in province" v-bind:key="f.pro_id" v-bind:value="f.pro_code">{{ f.pro_name }} </option>
                                
                                
                            </select>
                
                    <p class="error-mess" v-if="errorObj.proErr.length > 0">{{ errorObj.proErr[0] }}</p>

                    
                </div>
            
                <div class="form-group col-sm-4">
                    <label id="uDistrict"><b>Select District:</b>
                    </label>
                    
                            <select class="form-control" @change="changedist" name="district" id="district" v-model="registerObj.dis" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ds in Districts" :key="ds.dist_code" :value="ds.dist_code">{{ ds.dist_name }} </option>
                            </select>
                                
                          
                                <!-- <p><span>User country: {{ Prs.praddress.DistVal }}</span></p> -->
                    <p class="error-mess" v-if="errorObj.disErr.length > 0">{{ errorObj.disErr[0] }}</p>
                    
                </div>

                <div class=" form-group col-sm-4">
                    <label id="uPradeshiya"><b>Select Pradeshiya Sabha:</b>
                    </label>
                    
                            <select  class="form-control" name="pradeshiya" id="pradeshiya" v-model="registerObj.pra_sabha" >
                                <option value="" selected disabled>Choose</option>
                                <option v-for="ps in p_sabha" :key="ps.sb_code" :value="ps.sb_code">{{ ps.sb_name_en}}&nbsp;&nbsp;|&nbsp;&nbsp;{{ ps.sb_name_sin }} </option>
                            </select>
                            <p class="error-mess" v-if="errorObj.praErr.length > 0">{{ errorObj.praErr[0] }}</p>
                               
                </div>
                
            </div>      
        <div class="row">
                <div class="form-group col-md-6">
                    <label for="uName"><b>Name:</b>
                    </label>
                    <input type="text" name="uName" placeholder="EX: RMSP KUMARA" id="uName" class="form-control"
                        v-model="registerObj.name" />
                    <p class="error-mess" v-if="errorObj.nameErr.length > 0">{{ errorObj.nameErr[0] }}</p>
                    
                </div>
            
                <div class="form-group col-md-6">
                    <label for="uNic"><b>NIC:</b>
                    </label>
                    <input type="text" name="uNic"  placeholder="enter your  nic" id="uNic" class="form-control"
                        v-model="registerObj.nic" />
                    <p class="error-mess" v-if="errorObj.nicErr.length > 0">{{ errorObj.nicErr[0] }}</p>
                    <!-- <p><a href="https://thesrilanka.lk/old-to-new-nic-converter/" target="_blank">Click here to find Your 12 numbers NIC </a> </p> -->
                    <p>{{paddedNum}}</p>
                    <p>{{ len }}</p>
                </div>
                </div>
                
                <!-- <div class="form-group">
                    <label for="uEmail"><b>Email:</b>
                    </label>
                    <input type="email" name="uEmail" placeholder="example@gmail.com" id="uEmail" class="form-control"
                        v-model="registerObj.email" />
                    <p class="error-mess" v-if="errorObj.emailErr.length > 0">{{ errorObj.emailErr[0] }}</p>
                </div> -->
                <div class="row">
                <div class="form-group col-md-6">
              
                    <label for="uAddress"><b>Address:</b>
                    </label>
                    <input type="text" name="uAddress" placeholder="enter your address" id="uAddress" class="form-control"
                        v-model="registerObj.address" />
                    <p class="error-mess" v-if="errorObj.addressErr.length > 0">{{ errorObj.addressErr[0] }}</p>
                </div>
                <div class="form-group col-md-6">
                    <label for="uPhone"><b>Mobile:</b>
                    </label>
                    <input type="tel" name="uPhone" placeholder="enter your phone number" id="uPhone"
                        class="form-control" v-model="registerObj.phone" />
                    <p class="error-mess" v-if="errorObj.phoneErr.length > 0">{{ errorObj.phoneErr[0] }}</p>
                </div>
                </div>
                <div class="row">
                <div class="form-group col-md-6">
                    <label for="uPass"><b>password:</b>
                    </label>
                    <input type="password" name="uPass" placeholder="enter your password" id="uPass"
                        class="form-control" v-model="registerObj.pass" />
                    <p class="error-mess" v-if="errorObj.passErr.length > 0">{{ errorObj.passErr[0] }}</p>
                </div>
                

                <div class="form-group col-md-6">
                    <label for="uPassConfirm"><b>Re-type password:</b>
                    </label>
                    <input type="password" name="uPassConfirm" placeholder="enter your password again" id="uPassConfirm"
                        class="form-control" v-model="registerObj.confirm" />
                    <p class="error-mess" v-if="errorObj.confirmErr.length > 0">{{ errorObj.confirmErr[0] }}</p>
                </div>
                </div>

                
            
             <div class="form-group">
                    <label for="uBirth">* password must contain atleast one special character(please don't use '#')
                    </label>
                   
                </div> 

                <!-- <div class="form-group">
                    <label for="">Select your gender:
                    </label>
                    <div class="form-group">
                        <input type="radio" name="gender" value="male" id="genderMale"
                            v-model="registerObj.gender" /><span>Male</span>
                        <input type="radio" name="gender" value="female" id="genderFemale"
                            v-model="registerObj.gender" /><span>Female</span>
                    </div>
                    <p class="error-mess" v-if="errorObj.genderErr.length > 0">{{ errorObj.genderErr[0] }}</p>
                </div> -->
                <div class="row">
                    <div class="form-group col-md-4">
                        </div>
                    
                <div class="form-group col-md-4">
                    <input type="submit" value="Register" class="btn" />
                    <p>have an account? <router-link @click="scrollToTop()" to="/login">login</router-link>
                    </p>
                </div>
                
                </div>
            </form>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
// import axios, { setAxiosBaseURL } from "../axios"; 
export default {
    name: "Register",

    data() {
        return {
            registerObj: { pro:"",dis:"",pra_sabha:"",name: "",nic: "", email: "test@gmail.com", address: "",pass: "", confirm: "", phone: "",status:"0" },
            errorObj: { disErr: [],proErr: [], praErr: [],nameErr: [],nicErr: [], emailErr: [],addressErr: [], passErr: [], confirmErr: [], phoneErr: [] },
            matchUser: undefined,
            // numInput:null,
            paddedNum: null,
            len:'',
            p_sabha:[],
            Districts: [],
            province: [],

        }
    },
    created() {
           
           this.AllDistricts();
           this.Allprovince();
           this.AllprSabha();
        //    this.getSessionData();
           // this.loard();
           // this.AllShopData();
       },
    
    methods: {
        async getMatchUser(nic) {
            let data = await axios.get('/customersdata/' + nic);
            this.matchUser = data.data;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },
        addZero(num) {

                num = num.toString();
                    return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
            },
        addZeroPadding: function() {
                this.len= this.registerObj.nic.charAt(9).toUpperCase()
                // Check if the user input is a valid number
                if (!isNaN(parseInt(this.registerObj.nic)) && this.registerObj.nic !== null && (this.registerObj.nic.charAt(9).toUpperCase() === 'V' || this.registerObj.nic.charAt(9).toUpperCase() === 'X')) {
                    
                    this.paddedNum = this.addZero(this.registerObj.nic);
                  
                }else{
                    this.paddedNum=parseInt(this.registerObj.nic);
                }
                return this.paddedNum
        },
        async Allprovince() {
           
           this.province = (await axios.get('/provinces/' )).data;
          
          },
          async AllDistricts() {
           this.Districts = (await axios.get('/districts/')).data;
           
          },
          async AllprSabha() {
           this.p_sabha = (await axios.get('/Prasabha/' )).data;
    
          },
          async changeprovince() {

                let DistrictsData = (await  axios.get('/districts/' +this.registerObj.pro));
                this.Districts=DistrictsData.data

                },
                async changedist() {

                let SabhaData = (await axios.get('/Prasabha/' + this.registerObj.dis));
                this.p_sabha=SabhaData.data;


                }, 

        resetCheckErr: function () {
            this.errorObj.nameErr = [];
            this.errorObj.nicErr= [];
            this.errorObj.emailErr = [];
            this.errorObj.addressErr = [];
            this.errorObj.passErr = [];
            this.errorObj.confirmErr = [];
            this.errorObj.phoneErr = [];
            
        },

        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },

        checkForm: function () {
            this.resetCheckErr();

            // Name validate
            if (!this.registerObj.name) {
                this.errorObj.nameErr.push("Entering a name is required");
            }
            else {
                if (!/^[A-Za-z]+$/.test(this.registerObj.name.replace(/\s/g, ""))) {
                    this.errorObj.nameErr.push('A name can only contain letters');
                }
            }
// Adress validate
if (!this.registerObj.address) {
                this.errorObj.addressErr.push("Entering a address is required");
            }
            else {
                // if (!/^[A-Za-z]+$/.test(this.registerObj.address.replace(/\s/g, ""))) {
                //     this.errorObj.addressErr.push('A address can only contain letters');
                // }
            }
            // Email validate
            // if (!this.registerObj.email) {
            //     this.errorObj.emailErr.push("Entering a email is required");
            // }
            // else {
            //     if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/.test(this.registerObj.email)) {
            //         this.errorObj.emailErr.push('Email must be valid');
            //     }
            // }

            // Pass validate
            if (!this.registerObj.pass) {
                this.errorObj.passErr.push('Password is required');
            }
            else {
                if (!/[!@#$%^&*]/.test(this.registerObj.pass)) {
                    this.errorObj.passErr.push('Password must contain at least 1 special character');
                }

                if (this.registerObj.pass.length < 8) {
                    this.errorObj.passErr.push('Password must be more than or equal 8 characters');
                }
            }

            // Confirm Pass validate
            if (!this.registerObj.confirm) {
                this.errorObj.confirmErr.push('Confirm password is required');
            }
            else {
                if (this.registerObj.pass !== this.registerObj.confirm) {
                    this.errorObj.confirmErr.push('Confirm password must be match with password');
                }
            }


            // Phone validate
            if (!this.registerObj.phone) {
                this.errorObj.phoneErr.push('Entering phone number is required');
            }
            else {
                // if (!this.registerObj.phone.startsWith('84')) {
                //     this.errorObj.phoneErr.push('Phone numbers must start with 84');
                // }

                if (this.registerObj.phone.length != 10) {
                    this.errorObj.phoneErr.push('Phone numbers must have exactly 10 digits');
                }
                else{
                    if(!/[0-9]{10}/.test(this.registerObj.phone)) {
                    this.errorObj.phoneErr.push('Phone numbers should contain 10 Digits, no spaces allowed');
                    }
                 }
            }
 // NIC validate
                if (!this.registerObj.nic) {
                this.errorObj.nicErr.push('Entering NIC number is required');
                }else {
                    // if (this.registerObj.nic.length !==12)
                    // {
                    //     if((this.registerObj.nic.charAt(9).toUpperCase() !== 'V') || (this.registerObj.nic.charAt(9).toLowerCase() !== 'v') || (this.registerObj.nic.charAt(9).toUpperCase() !== 'X') || (this.registerObj.nic.charAt(9).toLowerCase() !== 'x'))
                    //       {
                    //           this.errorObj.nicErr.push('Please Enter Correct Nic Number');
                    //       }
                          
                    // }
                    if ( this.registerObj.nic.length !== 12 &&
                            !(
                                this.registerObj.nic.charAt(9).toUpperCase() === 'V' ||
                                this.registerObj.nic.charAt(9).toLowerCase() === 'v' ||
                                this.registerObj.nic.charAt(9).toUpperCase() === 'X' ||
                                this.registerObj.nic.charAt(9).toLowerCase() === 'x'
                            )
                        ) {
                            this.errorObj.nicErr.push('Please Enter Correct Nic Number');
                        }
            }
            
            if (!this.registerObj.pro) {
                this.errorObj.proErr.push('Province is required');
                }
                if (!this.registerObj.dis) {
                this.errorObj.disErr.push('District is required');
                }
                if (!this.registerObj.pra_sabha) {
                this.errorObj.praErr.push('Sabha is required');
                }
     
        },

        async handleSubmit(e) {
            this.checkForm();

            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                await this.getMatchUser(this.registerObj.nic);
                if (this.matchUser) {
                    this.errorObj.nicErr.push("Account already exist")
                }
                else {
                    let provinceBackendUrls;

                    if (this.registerObj.pro === 'PRO1') {
                        // provinceBackendUrls = "http://" + window.location.hostname.toString() + ":4000/api";
                         provinceBackendUrls = "https://elgservices.lk/acc";
                    } else {
                        // provinceBackendUrls = "http://" + window.location.hostname.toString() + ":3000/api";
                         provinceBackendUrls = "https://elgservices.lk/api";
                    }

                    // Set Axios base URL dynamically
                    axios.defaults.baseURL = provinceBackendUrls;
                    console.log(`Base URL updated to: ${axios.defaults.baseURL}`);
                    // setAxiosBaseURL(this.registerObj.pro);
                    // const bcrypt = require('bcrypt');
                    let Nic12 = this.addZeroPadding();
                    let data = {
                        // province:this.registerObj.pro,
                        // district:this.registerObj.dis,
                        // sabha:this.registerObj.pra_sabha,
                        cus_name: this.registerObj.name,
                        cus_nic: Nic12,
                        cus_address: this.registerObj.address,
                        cus_email: this.registerObj.email,
                        cus_contact: this.registerObj.phone,
                        cus_password: this.registerObj.pass,
                        // cus_password: await bcrypt.hash(this.registerObj.pass, saltRounds),
                        cus_status:this.registerObj.status,
                    }
                    let datasabha = {
                        province:this.registerObj.pro,
                        district:this.registerObj.dis,
                        sabha:this.registerObj.pra_sabha,
                        cus_nic: Nic12,
                    }
                    await axios.post("/customersdata/", data);
                    await axios.post("/addcustomersabha/", datasabha);
                    
                    this.$router.push("/login");
                }
            }
        }
    },

};
</script>


<style scoped>
.register-container {
    /* padding: 1rem 2%; */
     min-height: 77vh;
     padding-top: 1rem;
       background-image: url("../assets/images/regbacground.jpg");
    width: 100%;
    /* height: 50%; */
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size:cover;
  padding-left: 3%;
}

.register-container .register-form-container {
    background: #ffffffe1;
      width: 95%;
    
      /* padding-left: 5%; */

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
  
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.6rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
     border: margin-bottom; 
}

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    color:#620a0a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    background: #620a0a;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>
