// import axios from "axios";

// window.axios = axios
// axios.defaults.withCredentials = false
// // axios.defaults.baseURL = "http://localhost:8081/api"
// // axios.defaults.baseURL = "http://localhost:8081/api"
//  let backendUrl = "http://" + window.location.hostname.toString() + ":3000/api"
// // let backendUrl = "https://elgservices.lk/api"
// // let backendUrl = "https://elgservices.lk/acc" // newacc
// axios.defaults.baseURL = backendUrl

import axios from "axios";

// Retrieve backend URL from session storage, or use a default value
// const storedBackendUrl =
//   window.sessionStorage.getItem("backendUrl") ||
//   "http://" + window.location.hostname.toString() + ":3000/api";

    const storedBackendUrl =
  window.sessionStorage.getItem("backendUrl") ||
  "https://elgservices.lk/api";

// Set Axios defaults
axios.defaults.baseURL = storedBackendUrl;
axios.defaults.withCredentials = false; // or `true` if needed

// Optionally, log the base URL for debugging purposes
console.log(`Axios base URL set to: ${axios.defaults.baseURL}`);

// Export Axios instance for use in your application
export default axios;
